const FontFaceObserver = require('fontfaceobserver');

function isFontRequested(toggleClass) {
  return document.documentElement.className.indexOf(toggleClass) !== -1;
}

function signalWhenFontLoaded(fontFamily, loadedClass) {
  const regularFFO = new FontFaceObserver(fontFamily);
  const boldFFO = new FontFaceObserver(fontFamily, { weight: 'bold' });

  return Promise.all([regularFFO.load(), boldFFO.load()])
    .then(() => {
      document.documentElement.className += ` ${loadedClass}`;
    })
    .catch((error) => {
      console.error(`Error loading ${fontFamily} font: ${error}`);
    });
}

function loadReithFont() {
  const myNav = navigator.userAgent.toLowerCase();
  const oldIE =
    myNav.indexOf('msie') !== -1 && parseInt(myNav.split('msie')[1], 10) <= 10;
  const supported = !oldIE;

  if (!supported) {
    return;
  }

  if (isFontRequested('b-reith-sans-font')) {
    signalWhenFontLoaded('ReithSans', 'b-reith-sans-loaded');
  }

  if (isFontRequested('b-reith-serif-font')) {
    signalWhenFontLoaded('ReithSerif', 'b-reith-serif-loaded');
  }
}

loadReithFont();
